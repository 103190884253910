<template>
  <!--begin::Page Content -->
  <b-card
    v-if="loadingComplete"
    :header="pageInfo.title"
    class="card-custom gutter-b"
    header-tag="header"
  >
    <b-card-text><p v-html="pageInfo.description"></p></b-card-text>
    <!--begin::Media File-->
    <b-card-text
      class="d-flex justify-content-center"
      v-if="pageInfo.project_assets"
    >
      <InputGenericThumbGallery :projectAssets="pageInfo.project_assets">
      </InputGenericThumbGallery>
    </b-card-text>
    <!--end::Media File-->
    <!-- begin::Page Type Standard Display -->
    <div v-if="pageInfo.page_type.id == 1">
      <standard-page :pageInfo="pageInfo"></standard-page>
    </div>
    <!-- end::Page Type Standard Display -->

    <!-- begin::Page Type panel Display -->
    <div v-if="pageInfo.page_type.id == 2">
      <panel-page></panel-page>
    </div>
    <!-- end::Page Type panel Display -->

    <!-- begin::Page Type RSS Display -->
    <div v-if="pageInfo.page_type.id == 3">
      <rss-page :pageInfo="pageInfo"></rss-page>
    </div>
    <!-- end::Page Type RSS Display -->

    <!-- begin::Page Type External Display -->
    <div v-if="pageInfo.page_type.id == 4">
      <external-page :pageInfo="pageInfo"></external-page>
    </div>
    <!-- end::Page Type External Display -->
  </b-card>
  <!--end::Page Content -->
</template>

<script>
import { mapGetters } from "vuex";
import StandardPage from "@/view/pages/page/StandardPage.vue";
import PanelPage from "@/view/pages/page/PanelPage.vue";
import RssPage from "@/view/pages/page/RssPage.vue";
import ExternalPage from "@/view/pages/page/ExternalPage.vue";
import InputGenericThumbGallery from "@/view/components/form-inputs/GenericThumbGallery.vue";
export default {
  name: "view_page",
  data() {
    return {
      pageLoader: {
        componentsCompleted: 0,
        componentsRequired: 0
      },
      error: {
        active: false,
        message: ""
      }
    };
  },
  components: {
    StandardPage,
    PanelPage,
    ExternalPage,
    RssPage,
    InputGenericThumbGallery
  },

  computed: {
    ...mapGetters(["projectInfo", "pageInfo"]),
    pageId: function() {
      return this.$route.params.id;
    },
    loadingComplete: function() {
      if (
        this.pageLoader.componentsCompleted >=
        this.pageLoader.componentsRequired
      ) {
        return true;
      } else {
        return false;
      }
    }
  },

  methods: {}
};
</script>
