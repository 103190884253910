<template>
  <!--begin::Page Panel Content -->
  <div v-if="loadingComplete">
    <waterfall :line-gap="lineGap" :watch="pageSections">
      <!-- begin: waterfall slot -->
      <waterfall-slot
        v-for="(item, index) in pageSections"
        :width="item.width"
        :height="item.height"
        :order="index"
        :key="index"
        :class="`list-complete-item ${widthClass}`"
      >
        <router-link :to="{ path: `/page/${pageInfo.id}/section/${item.id}` }">
          <img class="list-complete-img card-custom" :src="item.img" alt />
          <div class="overlay">
            <div class="text"><p v-html="item.hovertext"></p></div>
          </div>
        </router-link>
      </waterfall-slot>
      <!-- end: waterfall slot -->
    </waterfall>
  </div>
  <!--end::Page Panel Content -->
  <!--begin::Loading Spinner-->
  <div class="row" v-else>
    <div class="col-xxl-12">
      <div class="card card-custom bgi-no-repeat card-stretch gutter-b">
        <div class="card-body my-4 pd-12">
          <div class="d-flex justify-content-center mb-3">
            <b-spinner class="main-card-loader" label="Loading..."></b-spinner>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--end::Loading Spinner-->
</template>
<script>
import Waterfall from "vue-waterfall/lib/waterfall";
import WaterfallSlot from "vue-waterfall/lib/waterfall-slot";
import { mapGetters } from "vuex";
export default {
  name: "PanelPage",
  components: {
    Waterfall,
    WaterfallSlot
  },
  data() {
    return {
      pageLoader: {
        componentsCompleted: 0,
        componentsRequired: 1
      },
      error: {
        active: false,
        message: ""
      }
    };
  },

  mounted() {
    this.pageLoader.componentsCompleted++;
  },

  computed: {
    ...mapGetters(["projectWidgets", "pageInfo"]),

    lineGap: function() {
      if (this.projectWidgets.length) {
        return 263;
      } else {
        return 409;
      }
    },

    widthClass: function() {
      if (this.projectWidgets.length) {
        return "item-s";
      } else {
        return "item-lg";
      }
    },

    pageSections: function() {
      let arr = [];
      this.pageInfo.page_sections.forEach(element => {
        let width = 250;
        let targetHeight = 170;
        let projectAsset = element.panelPicture.projectAsset;
        if (projectAsset.height) {
          let ratio = Math.min(targetHeight / projectAsset.height);
          width = projectAsset.width * ratio;
        }
        arr.push({
          id: element.id,
          width: width,
          height: targetHeight,
          hovertext: element.title,
          img: projectAsset.signedUrl
        });
      });

      return arr;
    },

    loadingComplete: function() {
      if (
        this.pageLoader.componentsCompleted >=
        this.pageLoader.componentsRequired
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>

<style scoped>
.list-complete-item {
  height: auto;
  display: inline-block;
}
.list-complete-item.item-s,
.list-complete-item.item-s a {
  width: 255px;
}
.list-complete-item.item-lg,
.list-complete-item.item-lg a {
  width: 400px;
}
.list-complete-item a {
  display: inline-block;
  position: relative;
  height: auto;
}
.list-complete-item a img {
  display: block;
  width: 100%;
  height: auto;
  border-radius: 5px;
}
.list-complete-item a .overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #008bba4d;
}
.list-complete-item a:hover .overlay {
  opacity: 1;
}
.list-complete-item a .text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}
</style>
