<template>
  <b-card class="p-0">
    <div class="h_iframe">
      <iframe
        :src="pageInfo.external_url"
        frameborder="0"
        :height="pageInfo.height"
        width="auto"
        allow="autoplay; encrypted-media"
        allowfullscreen
      ></iframe>
    </div>
  </b-card>
</template>
<script>
export default {
  name: "ExternalPage",
  props: {
    pageInfo: Object
  },
  data() {
    return {};
  },

  mounted() {},

  methods: {}
};
</script>
<style scoped>
.p-0 > div {
  padding: 0 !important;
}
</style>
