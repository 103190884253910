<template>
  <!--begin::Page RSS Content -->
  <div v-if="loadingComplete">
    <h2>{{ pageRssFeed.rss.channel.title }}</h2>
    <div
      v-for="(feed, index) in pageRssFeed.rss.channel.item"
      v-bind:key="index"
      class="d-flex align-items-center mb-10"
    >
      <!-- begin::RSS Feed -->
      <div class="d-flex flex-column flex-grow-1 font-weight-bold">
        <a
          :href="feed.link"
          class="text-primary text-hover-secondary mb-1 font-size-lg"
          >{{ feed.title }}</a
        >
        <span class="text-muted">{{ feed.pubDate }}</span>
      </div>
      <!--end::RSS Feed-->
    </div>
  </div>
  <!--end::Page RSS Content -->
  <!--begin::Loading Spinner-->
  <div class="row" v-else>
    <div class="col-xxl-12">
      <div class="card card-custom bgi-no-repeat card-stretch gutter-b">
        <div class="card-body my-4 pd-12">
          <div class="d-flex justify-content-center mb-3">
            <b-spinner class="main-card-loader" label="Loading..."></b-spinner>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--end::Loading Spinner-->
</template>
<script>
import { mapGetters } from "vuex";
import { POPULATE_PAGE_RSS_FEED } from "@/core/services/store/page/page.module";
export default {
  name: "RssPage",
  props: {
    pageInfo: Object
  },
  components: {},
  data() {
    return {
      pageLoader: {
        componentsCompleted: 0,
        componentsRequired: 1
      },
      error: {
        active: false,
        message: ""
      }
    };
  },
  mounted() {
    this.fetchRssPage();
  },

  methods: {
    fetchRssPage() {
      let payload = {
        projectId: this.projectInfo.id,
        pageId: this.pageId
      };
      this.$store
        .dispatch(POPULATE_PAGE_RSS_FEED, payload)
        .then(() => {
          this.pageLoader.componentsCompleted++;
        })
        .catch(() => {
          this.error.active = true;
          this.$router.push({ path: "/" });
          this.$toasted.global.error_toast({
            message: this.pageSectionErrors
          });
        });
    }
  },

  computed: {
    ...mapGetters(["pageRssFeed", "projectInfo", "pageSectionErrors"]),
    pageId: function() {
      return this.$route.params.id;
    },
    loadingComplete: function() {
      if (
        this.pageLoader.componentsCompleted >=
        this.pageLoader.componentsRequired
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>
