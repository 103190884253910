<template>
  <div class="accordion" role="tablist">
    <b-card
      no-body
      v-for="(section, index) in pageInfo.page_sections"
      v-bind:key="index"
    >
      <b-card-header header-tag="header" class="p-1 text-left" role="tab">
        <b-button
          v-if="collapsable"
          variant="outline-primary"
          @click="toggleAccordion(`accordion-${index}`)"
          :class="
            accordionsToShow.includes(`accordion-${index}`)
              ? 'not-collapsed'
              : 'collapsed'
          "
        >
          <span class="when-opened">
            <i class="fa fa-chevron-down" aria-hidden="true"></i>
          </span>
          <span class="when-closed">
            <i class="fa fa-chevron-right" aria-hidden="true"></i>
          </span>
          {{ section.title }}</b-button
        >
        <div v-else>
          {{ section.title }}
        </div>
      </b-card-header>
      <div
        :id="`accordion-${index}`"
        class="collapse"
        :class="{ show: accordionsToShow.includes(`accordion-${index}`) }"
      >
        <b-card-body>
          <b-card-text><p v-html="section.description"></p></b-card-text>
          <!--begin::Media File-->
          <b-card-text v-if="section.project_assets">
            <InputGenericThumbGallery :projectAssets="section.project_assets">
            </InputGenericThumbGallery>
          </b-card-text>
          <!--end::Media File-->
        </b-card-body>
      </div>
    </b-card>
  </div>
</template>
<script>
import InputGenericThumbGallery from "@/view/components/form-inputs/GenericThumbGallery.vue";
export default {
  name: "StandardPage",

  components: {
    InputGenericThumbGallery
  },

  props: {
    pageInfo: Object
  },
  data() {
    return {
      collapsable: true,
      accordionsToShow: []
    };
  },

  mounted() {
    if (this.sectionConfig == "OpenOnLoad") {
      this.pageInfo.page_sections.forEach((sections, index) => {
        this.accordionsToShow.push(`accordion-${index}`);
      });
    }
    if (this.sectionConfig == "Uncollapsable") {
      this.collapsable = false;
      this.pageInfo.page_sections.forEach((sections, index) => {
        this.accordionsToShow.push(`accordion-${index}`);
      });
    }
  },

  methods: {
    toggleAccordion(targetAccordion) {
      if (this.accordionsToShow.includes(targetAccordion)) {
        this.accordionsToShow = this.accordionsToShow.filter(
          item => item !== targetAccordion
        );
      } else {
        this.accordionsToShow.push(targetAccordion);
      }
    }
  },
  computed: {
    sectionConfig: function() {
      switch (this.pageInfo.section_type) {
        case 1:
          return "Closed";
        case 2:
          return "OpenOnLoad";
        case 3:
          return "Uncollapsable";

        default:
          return null;
      }
    }
  }
};
</script>
<style scoped>
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
.card {
  border: none;
}
.btn-outline-primary {
  text-align: left;
  border: none;
}
.accordion .card .card-header {
  background-color: unset;
}
</style>
